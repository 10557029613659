import { Prop } from 'vue-property-decorator';

import CmsAccordion from '@/commoncomponents/CmsAccordion/CmsAccordion.vue';
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import CmsCardComponent from '@/commoncomponents/CmsCardComponent/CmsCardComponent.vue';
import CmsLabelComponent from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue';
import CmsTagComponent from '@/commoncomponents/CmsTagComponent/CmsTagComponent.vue';
import BreadcrumbComponent from '@/commoncomponents/breadcrumbComponent/BreadcrumbComponent.vue';
import MobileScreenUnavailable from '@/commoncomponents/mobileScreenUnavailable/MobileScreenUnavailable.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import { Component, Mixins } from 'vue-property-decorator';
import Checkmark from '../../../../assets/images/cms/checkmark.svg';
import ChevronRight from '../../../../assets/images/cms/chevron_right_nav_link.svg';
import Bullet from '../../../../assets/images/cms/link_bullet_blue.svg';
import ResourceLink from '../../../../assets/images/cms/resource_link.svg';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import APP_CONST from '@/constants/AppConst';
import {
  PDFViewPageData,
  getCopyrightMessage,
  pdfResourceResponseToData
} from '@/utilities/cmsUtilities';
import axios from 'axios';

@Component({
  components: {
    'bread-crumb': BreadcrumbComponent,
    'mobile-unavailable-screen': MobileScreenUnavailable,
    'cms-card': CmsCardComponent,
    'collapsible-layout-card': CmsAccordion,
    'cms-tag-list': CmsTagComponent,
    'cms-content-label': CmsLabelComponent,
    'card-carousel': CmsCardCarousel,
    'bouncing-preloader': BouncingPreloaderComponent,
    'vue-pdf-embed': VuePdfEmbed
  }
})
export default class RoutineLandingPage extends Mixins(
  DeviceWidthCheckerMixin
) {
  //Images from assets folder
  checkmarkImage = Checkmark;
  resourceLinkImage = Bullet;
  navLinksChevron = ChevronRight;
  linkIcon = ResourceLink;

  buttonType: 'VIEW' | 'DOWNLOAD' | 'LINK' = 'DOWNLOAD';

  resourceId: string = '';
  @Prop()
  resourceIdQuery: string | undefined;

  routineData: PDFViewPageData = {
    title: '',
    id: '',
    allowDownload: false,
    fileUrl: '',
    contentType: ''
  };
  loading: boolean = true;
  rendered: boolean = false;
  page: any = null;
  pageCount = 1;
  pdfSource = '';
  showAllPages = true;
  pdfData: Uint8Array | undefined = undefined;
  renderStart: any;
  renderEnd: any;
  goToPage: string = '';

  scrollToPage(page: number){
    try {
      const pdfRef: any = this.$refs.pdfRef;
      const pageElement = pdfRef.$el.childNodes[page - 1];
      pageElement.scrollIntoView();
    }
    catch (error) {
      console.error('Failed to scroll to page.', error);
    }
  }

  goToPageHandler(event: any) {
    const val = this.goToPage;
    const num = Number(val);
    if (Number.isInteger(num)) {
      if (!this.showAllPages) {
        this.page = num;
      }
      else {
        this.scrollToPage(num);
      }
    }
  }

  handleDocumentRender(event: any) {
    const pdfRef: any = this.$refs.pdfRef;
    const count = pdfRef.pageCount;
    if(Number.isInteger(count)){
      this.pageCount = count;
    }
    this.rendered = true;
    this.renderEnd = new Date();
    console.debug(`PDF Render took ${this.renderEnd - this.renderStart}`);
  }


  handleDocumentLoaded(event: any) {
    const pdfRef: any = this.$refs.pdfRef;
    this.pageCount = pdfRef.pageCount;
    this.renderStart = new Date();
  }

  async fetchPdfData(url: string) {
    const data = await axios({
      url,
      method: 'GET',
      responseType: 'blob'
    })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error('Error fetching PDF', err);
      });
    if (data && data.size > 4000000) {
      //PDF is rougly longer than 25 pages, we will show the single page view.
      console.debug('Large PDF, displaying single page view.');
      this.showAllPages = false;
      this.page = 1;
    }
    const uint8Array = new Uint8Array(await data.arrayBuffer());
    this.pdfData = uint8Array;
    this.loading = false;
  }

  async fetchData() {
    return axios({
      url: `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`,
      headers: {
        Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}`
      },
      method: 'post',
      data: {
        query: `
        query {
          mediaResource(id: "${this.resourceId}") {
            sys {
              id
            }
            itemTitle
            itemDescription
            itemShortDescription
            grades
            url
            resourceType
            allowFileDownload
            file{
                url
                fileName
                contentType
            }
            itemHeroImage	{
              url
            }
            itemThumbnailLogo {
              url
            }
            tags
          }
        }
          `
      }
    })
      .then(result => {
        const structuredData = pdfResourceResponseToData(result);
        this.routineData = {
          ...structuredData
        };
        if (structuredData.contentType.toLowerCase() !== 'application/pdf') {
          alert('File is not a PDF, please contact support.');
          this.$router.push({
            path: APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LANDING.path,
            query: { id: this.resourceIdQuery }
          });
        }
        this.pdfSource = structuredData.fileUrl;
        this.fetchPdfData(structuredData.fileUrl);
        return structuredData;
      })
      .catch(err => {
        console.error('Failed to Fetch Landing Page', err);
        //ToDo: handle failed fetch here.
      });
  }

  async updatedId() {
    const id: string = (this.$route.query.id as string) || '';
    this.loading = true;
    this.resourceId = id;
    this.fetchData();
  }

  debounce(func: any) {
    let timer: any;
    return function(event: any) {
      /* istanbul ignore if */
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(func, 200, event);
    };
  }

  updateShowAll() {
    this.page = this.showAllPages
      ? null
      : 1;
    this.goToPage = '';
  }

  handleResize = this.debounce(() => {
    // Re rendering on page size disabled while fixed size
    // const pdfRef: any = this.$refs.pdfRef;
    // pdfRef.render();
  });

  created() {
    window.addEventListener('resize', this.handleResize);
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  beforeMount() {
    const id = this.$route.query.id;
    if (id) {
      this.resourceId = id.toString();
    }
    this.$watch('$route', this.updatedId);
    this.$watch('showAllPages', this.updateShowAll);
    this.fetchData();
  }

  getCopyrightMessage = getCopyrightMessage;
}
