





















































































import ViewPdfResource from "./ViewPdfResource";
export default ViewPdfResource;
